import cx from 'classnames';

const TextArea = ({ name, handleChange, alertsBorder }) => {
  return (
    <textarea
      className={cx({ 'alerts-border': alertsBorder })}
      name={name}
      rows="4"
      cols="50"
      tabIndex="1"
      onChange={handleChange}
    ></textarea>
  );
};

export default TextArea;
