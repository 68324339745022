import { useNavigate, useParams } from 'react-router-dom';
import { useSurvey } from '../../context/SurveyProvider';
import BackgroundAssets from '../BackgroundAssets/BackgroundAssets';

const Sorry = () => {
  const { surveyName } = useParams();
  const { surveyData } = useSurvey();
  const navigate = useNavigate();

  return (
    <div className="step-last">
      <BackgroundAssets />
      <main className="thank-you sorry">
        <h1>SORRY!</h1>
        <fieldset>
          <p>
            You need to answer those survey questions correctly! Are you sure you listened to the podcast?
          </p>
          <p>
            Listen to the podcast{' '}
            <a href={surveyData.spotifyLink} target="_blank" id="back-and-listen">
              on spotify
            </a>{' '}
            or{' '}
            <a href={surveyData.appleLink} target="_blank" id="back-and-listen">
              on apple podcasts
            </a>{' '}
            and{' '}
            <span className="try-again" onClick={() => navigate(`/survey/${surveyName}/quiz`)}>
              TRY AGAIN
            </span>
          </p>
        </fieldset>
      </main>
    </div>
  );
};
export default Sorry;
