import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSurvey } from '../../context/SurveyProvider';
import BackgroundAssets from '../BackgroundAssets/BackgroundAssets';
import { getSurveyAnswers, updateSurveyAnswers } from '../../utils/utils';
import cx from 'classnames';
import { saveFeedback, submitSurvey } from '../../api/survey';
import RadioButton from '../RadioButton/RadioButton';
import TextArea from '../TextArea/TextArea';

const Feedback = () => {
  const { surveyName } = useParams();
  const navigate = useNavigate();
  const { surveyData } = useSurvey();

  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [feedbackAnswers, setFeedbackAnswers] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (surveyData.questions) {
      const questions = surveyData.questions.filter((q) => q.type === 'question');
      setFeedbackQuestions(questions);
    }
  }, [surveyData]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFeedbackAnswers((prev) => ({ ...prev, [name]: value }));

    if (value.trim()) {
      clearErrors(name);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
      event.target.previousSibling.click();
    }
  };

  useEffect(() => {
    updateSurveyAnswers('feedback', {
      ...feedbackAnswers,
    });
  }, [feedbackAnswers]);

  const clearErrors = (field) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const validateFeedback = () => {
    let validated = true;
    const newErrors = {};

    feedbackQuestions.forEach((question) => {
      if (!feedbackAnswers[question.fieldName] || feedbackAnswers[question.fieldName].trim() === '') {
        newErrors[question.fieldName] = 'Required';
        validated = false;
      }
    });

    setErrors(newErrors);

    return validated;
  };

  const handleNextStep = async (event) => {
    event.preventDefault();

    if (validateFeedback()) {
      const body = { surveyId: surveyData.surveyId, ...getSurveyAnswers() };

      try {
        await saveFeedback(body);
        await submitSurvey(surveyData);
      } catch (error) {
        console.error(error);
      }

      navigate(`/survey/${surveyName}/thank-you`);
    }
  };

  useEffect(() => {
    const surveyAnswers = getSurveyAnswers();

    if (!surveyAnswers.quiz) {
      navigate(`/survey/${surveyName}/quiz`);
      return;
    }

    const quizAnswers = surveyData.questions
      ?.filter((q) => q.type === 'test')
      .every((q) => surveyAnswers.quiz[q.fieldName]);

    if (!quizAnswers && surveyData.questions) {
      navigate(`/survey/${surveyName}/quiz`);
    }
  }, [surveyData]);

  const renderFeedbackQuestions = () => {
    return feedbackQuestions.map((question, index) => {
      return (
        <fieldset key={index}>
          <legend>{question.question}</legend>
          {question.inputType === 'radio' ? (
            <div className={cx('radio-btn__wrapper', { 'alerts-border': errors[question.fieldName] })}>
              {question.answers.map((answer, answerIndex) => (
                <RadioButton
                  key={answerIndex}
                  name={question.fieldName}
                  value={answer.value}
                  handleClick={handleChange}
                  handleKeyDown={handleKeyDown}
                  text={answer.answer}
                />
              ))}
            </div>
          ) : (
            <TextArea
              name={question.fieldName}
              handleChange={handleChange}
              alertsBorder={errors[question.fieldName]}
            />
          )}
          {errors[question.fieldName] && <div className="err-msg">{errors[question.fieldName]}</div>}
        </fieldset>
      );
    });
  };

  return (
    <div className="step-three">
      <BackgroundAssets />
      <main>
        <h1>mowPod Survey: The Maverick Show with Matt Bowles</h1>
        <form className="survey-form step3" id="3">
          <legend>Lastly, give us your feedback on the show!</legend>
          {renderFeedbackQuestions()}
          <div className="bottom__next-step">
            <button className="submit-btn" onClick={handleNextStep}>
              NEXT
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Feedback;
