import { useParams, useNavigate } from 'react-router-dom';
import { useSurvey } from '../../context/SurveyProvider';
import BackgroundAssets from '../BackgroundAssets/BackgroundAssets';
import { useEffect, useState } from 'react';
import { checkIntroductionFilled, getSurveyAnswers, updateSurveyAnswers } from '../../utils/utils';
import { checkQuizAnswers } from '../../api/survey';
import RadioButton from '../RadioButton/RadioButton';

const Quiz = () => {
  const { surveyName } = useParams();
  const navigate = useNavigate();
  const { surveyData } = useSurvey();

  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizAnswers, setQuizAnswers] = useState({});

  useEffect(() => {
    if (surveyData.questions) {
      const questions = surveyData.questions.filter((q) => q.type === 'test');
      setQuizQuestions(questions);
    }
  }, [surveyData.questions]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setQuizAnswers((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    updateSurveyAnswers('quiz', {
      ...quizAnswers,
    });
  }, [quizAnswers]);

  const validateQuiz = () => {
    const correctAmountOfAnswers = Object.values(quizAnswers).length === quizQuestions.length;
    return correctAmountOfAnswers;
  };

  const handleNextStep = (event) => {
    event.preventDefault();

    if (validateQuiz()) {
      const body = { surveyId: surveyData.surveyId, ...getSurveyAnswers() };

      checkQuizAnswers(body)
        .then((response) => {
          response.successful
            ? navigate(`/survey/${surveyName}/feedback`)
            : navigate(`/survey/${surveyName}/sorry`);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    const isFilled = checkIntroductionFilled(getSurveyAnswers());

    if (!isFilled) {
      navigate(`/survey/${surveyName}/introduction`);
    }
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
      event.target.previousSibling.click();
    }
  };

  const renderQuizQuestions = () => {
    return quizQuestions.map((question, index) => (
      <fieldset key={index}>
        <legend>{question.question}</legend>
        <div className="radio-btn__wrapper">
          {question.answers.map((answer, answerIndex) => (
            <RadioButton
              key={answerIndex}
              name={question.fieldName}
              value={answer.value}
              handleClick={handleChange}
              handleKeyDown={handleKeyDown}
              text={answer.answer}
            />
          ))}
        </div>
      </fieldset>
    ));
  };

  return (
    <div className="step-two">
      <BackgroundAssets />
      <main>
        <h1>mowPod Survey: {surveyData.name}</h1>
        <form className="survey-form step2" id="2">
          <legend>Answer the below quiz to show you listened to the episode!</legend>
          {renderQuizQuestions()}
          <div className="bottom__next-step">
            <button className="submit-btn" onClick={handleNextStep}>
              NEXT
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Quiz;
