export const getSurveyAnswers = () => {
  const surveyData = JSON.parse(sessionStorage.getItem('survey')) || {};
  return surveyData;
};

export const updateSurveyAnswers = (step, newAnswers) => {
  const surveyData = JSON.parse(sessionStorage.getItem('survey')) || {};

  surveyData[step] = {
    ...surveyData[step],
    ...newAnswers,
  };

  sessionStorage.setItem('survey', JSON.stringify(surveyData));
};

export const checkIntroductionFilled = (answers) => {
  if (!answers.introduction) {
    return false;
  }

  const { email, name } = answers.introduction;

  return !!email && !!name;
};

export const scrollToRef = (ref) => {
  if (ref && ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};
